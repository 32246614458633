import React from "react";
import Link from "gatsby-link";
//import PageHeader from "../components/Common/PageHeader";
import "./index/index.less";
import Img from "gatsby-image";
import { just_excerpt } from "../utils/textutil";
import Layout from "../components/layout"
import {pages_transform} from "../utils/pageutil"


const NewsItem = (post, myclass, key, large = false) => (
  <div key={key} className={`single-news ${myclass}`}>
    <article>
      {post.featured_media && post.featured_media.childImageSharp ? (
        <Img
          className="limit-img-height"
          sizes={post.featured_media.childImageSharp.sizes}
          alt=""
        />
      ) : null}

      {/* { node.featured_media ? (<Img resolutions={large ? node.featured_media.localFile.childImageSharp.largeResolutions : node.featured_media.localFile.childImageSharp.thumbnailResolutions} alt="" />) : null } */}
      <div className="content">
        <h3>{post.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: just_excerpt(post.excerpt) }} />
        
        <span className="date">{post.date}</span>
      </div>
      <Link to={"/blog-" + post.post_id + ".html"} className="link" />
    </article>
  </div>
);

const BlogPage = ({ data }) => (
  <Layout>
  <div id="main-wrap">
    {/* Main Wrap  */}
    {/* Page Content  */}
    <div id="page-content" className="header-static">
      {/* Page Header  */}
      {/* <PageHeader title="最近動態"/> */}
      {/* END Page Header  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        <div className="container">
          {/*News */}
          {/* News Section  */}
          <section id="news" className="page">
            {/*Single News */}
            {NewsItem(
              pages_transform(data)[0],
              "first-item",
              "first-item",
              true
            )}

            {/*END Single News */}
            <div className="news-items equal three-columns">
              {/*Single News */}
              { pages_transform(data).map((post, i) => {
                //console.log(edge);
                return i > 0
                  ? NewsItem(post , "one-item", "item-" + i)
                  : null;
              })}
              {/*END Single News */}
            </div>
          </section>
          {/*END News */}
          {/* Navigation  */}
          {/* <section id="nav" className="padding-top-null">
                        <div className="row">
                            <div className="col-xs-6">
                                <div className="nav-left">
                                    <a href="single-blog-style-2.html" className="btn-alt small shadow margin-null"><i className="icon ion-ios-arrow-left"></i><span>Older posts</span></a>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="nav-right">
                                    <a href="#" className="btn-alt small shadow margin-null"><span>Newer posts</span><i className="icon ion-ios-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </section> */}
          {/* END Navigation  */}
        </div>
      </div>
    </div>
    {/* END Page Content */}
    {/* Main Wrap  */}
  </div>
  </Layout>
);


 
export const query = graphql`
query allPostQuery{
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
    edges{
      node{
      	... pageFields
      }
    }
  }
}
`
// export const query2 = graphql`
//   query BlogPageQuery {
//     allWordpressPost {
//       edges {
//         node {
//           id
//           slug
//           title
//           wordpress_id
//           featured_media {
//             localFile {
//               childImageSharp {
//                 sizes: sizes(maxWidth: 800, maxHeight: 450, cropFocus: CENTER) {
//                   ...GatsbyImageSharpSizes_withWebp
//                 }
//                 largeResolutions: resolutions(width: 1000, height: 600) {
//                   ...GatsbyImageSharpResolutions_withWebp
//                 }
//                 thumbnailResolutions: resolutions(width: 257, height: 155) {
//                   ...GatsbyImageSharpResolutions_withWebp
//                 }
//               }
//             }
//           }
//           excerpt
//           date(formatString: "YYYY年MMMD日", locale: "zh-TW")
//           modified(formatString: "YYYY年MMMD日", locale: "zh-TW")
//         }
//       }
//     }
//   }
// `;

export default BlogPage;
