export function just_excerpt(raw){
    const str = raw.replace(/<(?:.|\n)*?>/gm, '')
    const maxlen = 50;
    const target = "&hellip;";
    let result = '';
    if( str.length > maxlen ){
      result = str.substr(0,maxlen-1)+target;
    }else{
      result = str;
    }
    return result;
  }
  